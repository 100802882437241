
import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {Container, Section, Text, SuperHeading, Flex, Box, Heading, Kicker, Subhead, ButtonList, Button} from "./ui"
import * as styles from "./distributors-country-item.css"

export default function DistributorsCountryItem(props) {

    const countries = props.blocks.map((block) => block.country)
    const uniqueCountries = [...new Set(countries)];
    return (
        <div className={styles.distributorsContainer}>
            {uniqueCountries.map((country) => {
                return <Text className={styles.distributorsItemContainer} >
                    <div className={country === props.activeCountry ? styles.distributorsItemSelected : styles.distributorsItem} onClick={() => props.handleClick(country)}>
                        {country}
                    </div>
                </Text>
            })}
        </div>

    )
}

