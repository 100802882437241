import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import {useRef} from "react";
import DistributorsCountryItem from "../components/distributors-country-item";
import {Container, Flex, Section} from "../components/ui";

export default function Distributors(props) {
    const ref = useRef(null);
    const { distributorsPage } = props.data
    const [activeCountry, setActiveCountry] = React.useState('GREECE')
    const [distributorsForCountry, setDistributorsForCountry] = React.useState([])

    const handleClick = (country) => {
        setActiveCountry(country);
        const lastChildElement = ref.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        setDistributorsForCountry([])

        const distributorsForCountry = distributorsPage.blocks.
        filter((block) => {
            return block.country === country
        })
        setDistributorsForCountry(distributorsForCountry)

    };


    return (
        <Layout {...distributorsPage}>
            <DistributorsCountryItem blocks={distributorsPage.blocks} activeCountry={activeCountry} handleClick={handleClick} />
            <div ref={ref}>
                <Section>
                    <Container>
                        <Flex variant={"responsive"} alignItems={"start"} responsive={true} gap={40}>
                            {distributorsForCountry.length > 0 && distributorsForCountry
                                .map((block) => {
                                    const { id, blocktype, ...componentProps } = block
                                    const Component = sections[blocktype] || Fallback
                                    return <Component key={id} {...componentProps} />
                            })}
                        </Flex>
                    </Container>
                </Section>
            </div>
        </Layout>
    )
}

export const query = graphql`
  {
    distributorsPage {
      id
      title
      description
      blocks: content {
        id
        blocktype
        ...DistributorsItemContent
      }
      
    }
  }
`
